@import '../../../styles/base/variables.scss';
@import "../../../styles/mixins/breakpoints";

.form__item-group > div:first-child {
  span {
    //border-right: none;
    border-radius: 8px 0 0 8px;
  }
}
.form__item-group > div:last-child {
  span {
    border-left: none;
    border-radius: 0 8px 8px 0;
  }
}
.filter {
  &__field-wrapper {
    width: 256px;
    flex-shrink: 1;

    @include sm-only {
      width: 230px;

      label {
        font-size: 12px;
      }
    }
  }

  &__input {
    width: 128px;
    
    flex-shrink: 1;
    flex-grow: 0;

    @include sm-only {
      width: 115px;
    }
  }
}
