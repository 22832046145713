@import "../../../styles/base/variables.scss";

.news-window {
  &__title {
    margin-bottom: 20px;
  }

  &__images-wrapper {
    display: flex;
    justify-content: center;
  }

  &__image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin: 0 20px;
    padding: 10px;
    border: 1px solid $gray-6;
  }

  &__image {
    width: 100%;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}