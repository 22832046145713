@import '../../../../styles/base/variables';

.activate-2fa {
  &__title,
  &__info-title {
    margin-bottom: 10px;
    font-weight: 500;
  }

  &__note,
  &__info-note {
    margin-bottom: 10px;
    font-size: 12px;
    color: $black45;
  }

  &__button {
    margin-bottom: 10px;
    padding: 0;

    span {
      font-size: 12px;
    }
  }

  &__applications {
    margin-bottom: 16px;
    padding: 8px 16px;
    border: 1px solid $black06;
    border-radius: 8px;

    p {
      font-size: 12px;
      color: $black45;
    }
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  &__info {
    margin-bottom: 16px;
    padding: 8px 16px;
    border: 1px solid $black06;
    border-radius: 8px;
  }

  &__info-note {
    margin-bottom: 0;
  }
}
