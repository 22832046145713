@import '../../../../styles/base/variables';

.modal-2fa-codes {
  &__title {
    margin-bottom: 10px;
    font-weight: 500;
  }

  &__form {
    width: 100%;
  }
}
