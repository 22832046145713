@import '../../styles/mixins/breakpoints.scss';
@import '../../styles/base/variables';

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  min-width: 320px;
}

body * {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  // Pagination для таблиц отображается криво
  // line-height: 1.6;
}

#__next {
  height: 100%;
}

.main__wrapper {
  background-color: lightslategray;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block {
  position: relative;
  width: 100%;
  padding: 20px;
  margin-top: 25px;
  background-color: $white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  @include md {
    padding: 25px;
  }

  & > div > h2 {
    font-size: 16px;
  }

  &--mt0 {
    margin-top: 0;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.content {
  min-height: calc(100% - 75px);
  padding: 20px;
  background-color: $gray-4;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-link {
  cursor: pointer;
  background: transparent;
  border: 0;
}

.error__message {
  margin: 10px 0 0;
  color: $red;
}

// Скрывает стрелки для input type="number"
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

button.table-action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  min-height: 32px;
  padding: 4px 8px;

  // &--ruble {
  //   padding: 5px 8px 3px;
  // }
}

.table-action-link {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  min-height: 32px;
  padding: 4px 8px;
  border: 1px solid $gray-6;
  border-radius: 2px;
}

// Уменьшает ширину input в date-filter
.ant-picker-input > input {
  width: 73px !important;
}

// Скрывает панель showTime в date-filter
.ant-picker-time-panel {
  display: none !important;
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.table {
  width: 100%;

  .ant-table-thead * {
    font-weight: 500;
  }

  .ant-form-item-control-input {
    min-height: 20px;
  }

  .ant-form-item {
    margin: 0;
  }
}

.ant-form-item label {
  font-weight: 500;
}

.ant-tabs-tab {
  padding: 4px 0 20px 0 !important;
}

.d-sm-only-block {
  display: inline-block;

  @include sm-only {
    display: none !important;
  }
}

.d-sm-block {
  display: none !important;

  @include sm {
    display: block !important;
  }
}

.d-sm-none {
  display: block !important;

  @include sm {
    display: none !important;
  }
}

.d-md-block {
  display: none !important;

  @include md {
    display: block !important;
  }
}

.d-md-none {
  display: block !important;

  @include md {
    display: none !important;
  }
}

.d-lg-block {
  display: none !important;

  @include lg {
    display: block !important;
  }
}

.d-lg-none {
  display: block !important;

  @include lg {
    display: none !important;
  }
}

.uploader-logo .ant-upload-list-picture-card-container {
  width: 100%;
  height: auto;
}

div.picker-wrapper {
  width: 100%;
}

.date-filter-range-picker {
  margin: 0;
}

.sign-up {
  min-width: 280px;
  margin: 0 auto !important;
  padding: 0 !important;
  border-radius: 8px;
  overflow: hidden;

  & > div:nth-child(2) {
    width: 100%;
    background-color: $gray-10;

    & > * {
      background-color: $gray-10;
    }

    & > div:first-of-type {
      border-bottom: 1px solid $gray-6;
    }

    & > button svg {
      width: 18px;
      height: 18px;
    }
  }

  &__btn {
    width: 100%;
    color: $white !important;
    background-color: $dark-blue !important;
    border-color: $dark-blue !important;

    &:hover {
      opacity: 0.95;
      background: $dark-blue !important;
      border-color: $dark-blue !important;
    }

    &:focus {
      color: $white !important;
      border-color: $blue !important;
      background: $dark-blue !important;
    }
  }
}

.react-input-mask-item-disabled {
  input {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    caret-color: transparent;
    cursor: not-allowed !important;
    border: 1px solid #d9d9d9;
    box-shadow: none !important;

    &:focus,
    &:active,
    &:hover {
      border: 1px solid #d9d9d9;
      box-shadow: none !important;
    }
  }
}

.rbt-input-main {
  display: block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $gray-9;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    border-color: #40a9ff;
  }

  &:focus {
    border-color: #40a9ff;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px;
    outline: 0;
  }
}

.rbt-input-hint {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.54);
  left: 0px;
  pointer-events: none;
  position: absolute;
  top: 0px;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  font-weight: 400;
  height: 38px;
  line-height: 24px;
  margin: 0px;
  padding: 6px 12px;
}

.rbt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  width: 100% !important;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  margin-bottom: 2px;
  padding: 0.5rem 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: #16181b;
      text-decoration: none;
      background-color: #f8f9fa;
    }
  }
}

.rbt {
  input::placeholder {
    color: $gray-6;
  }
}
