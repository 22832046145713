@import '../../../styles/base/variables.scss';

.instruction {
  &__link {
    span {
      margin-left: 16px;
      padding: 5px 20px;
      border: 1px solid $tertiary;
      border-radius: 5px;
    }
  }

  &__icon {
    svg {
      font-size: 22px;
    }
  }
}
