@import '../../../styles/base/variables';
@import '../../../styles/mixins/breakpoints';
@import '../../../styles/mixins/visibility';

.user {
  &__container {
    cursor: pointer;

    & div:nth-child(2) {
      @include sm-only {
        @include visually-hidden;
      }
    }
  }
}
