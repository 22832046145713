.deactivate-2fa {
  &__restore-code-toggle-button {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 22px !important;
    height: 22px !important;
    margin: 0;
    padding: 0 !important;
  }
  
  &__restore-code-toggle-button-icon {
    svg {
      width: 14px;
      height: 14px;
    }
  }
}