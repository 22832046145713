// -------- Media queries breakpoints -----------
$screen-sm-only: 575px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

// -------- Colors -----------
$white: #fff;
$black: #000;
$gray-2: #fafafa;
$gray-4: #f5f5f5;
$gray-6: #bfbfbf;
$gray-8: #595959;
$gray-9: #d9d9d9;

// -------- Цвета из макета -----------
$gray-10: #f0f0f0;
$gray-12: #e5e5e5;

$green: #00ba34;
$red: #ff2525;
$light-red: #ff4d4f;
$blue: #168fff;
$purple: #36369f;
$dark-blue: #1f183f;
$light-blue: #e6f7ff;

$black85: rgba(#000, 85%);
$black45: rgba(#000, 45%);
$black25: rgba(#000, 25%);
$black06: rgba(#000, 6%);

// -------- Antd Colors -----------
$primary: #62DAAB;
$secondary: #7666F9;
$tertiary: #6395F9;
$quaternary: #D62A0D;
$quinary: #FAA219;
$senary: #5B8FF9;
$septenary: #65789B;
