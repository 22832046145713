@import '../../../styles/base/variables.scss';

.modal-wrapper {
  &__icon svg {
    font-size: 30px;
  }

  &__text {
    margin: 0;

    &--warning {
      color: $quaternary;
    }
  }
}