@import '../../styles/base/variables';
@import '../../styles/mixins/breakpoints';

.sidebar {
  flex: 0 0 240px;
  width: 240px;
  height: 100%;
  background-color: $white;

  @include lg {
    height: 100vh;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: calc(97vh - 55px);
    overflow-y: auto !important;

    @include lg {
      height: 97vh;
    }
  }

  &__image-wrapper {
    margin: auto 20px 0;
  }

  &__menu {
    padding: 20px 0;
    height: 90%;
  }

  &__menu-title {
    font-size: 0.9em;
    color: #969696;
  }
}
