@import '../../../styles/base/variables.scss';

.company {
  // position: relative;
  display: flex;
  padding: 0 20px !important;

  &__logo {
    flex: 0 0 36px;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    overflow: hidden;
  }

  &__dropdown {
    border-radius: 8px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 10px;

    * {
      text-align: left;
    }
  }

  &__name {
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 500;
    word-break: break-word;
  }

  &__legaly {
    font-size: 10px;
    margin-bottom: 0;
  }

  &__inn {
    margin-bottom: 0;
    font-size: 10px;
    color: $gray-6;
  }

  &__menu {
    padding: 0;
    border-radius: 0;

    &--scroll {
      max-height: 240px;
      overflow-y: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }

  &__item {
    min-height: 40px;
    border-bottom: 1px solid $black06;
    border-radius: 0;
    background-color: $white !important;

    &:hover,
    &:active,
    &:focus {
      background-color: $gray-4 !important;
    }

    &--new {
      border-bottom: none;
      text-align: center;
    }

    p {
      margin: 0 0 0 10px;
      text-align: left;
      line-height: 1.2;

      &:first-child {
        font-weight: 600;
      }

      &:last-child {
        font-size: 10px;
      }
    }
  }
}
