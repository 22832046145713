@import '../../../styles/base/variables';
@import '../../../styles/mixins/breakpoints';

.control {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include sm-only {
    flex-wrap: wrap;
  }

  &__add-button {
    order: 0;
  }

  &__info-icon {
    @include sm {
      order: 2;
    }
  }

  &__search-field {
    order: 2;
    margin: 10px 0 0;

    @include sm {
      order: 1;
      margin: 0 8px;
    }
  }
}
