@import "../../styles/base/variables";
@import "../../styles/mixins/breakpoints";
@import "../../styles/mixins/visibility";

.header {
  min-height: 50px;
  padding: 5px 20px;
  background-color: $gray-10;

  @include lg {
    padding: 10px 25px;
  }

  &__drawer {
    div {
      padding-left: 0;
      padding-right: 0;
    }

    & > div:last-child {
      width: 240px !important;

      & > div > div > div:first-child {
        padding-left: 20px;
      } 
    }
  }

  &__menu {
    @include lg {
      display: none;
    }
  }

  &__title {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.1;

    @include lg {
      font-size: 20px;
    }
  }

  // &__dropdown {
  //   display: inline-block;
  //   border-color: $gray-8 transparent transparent;
  //   border-style: solid;
  //   border-width: 6px 4px 0;
  // }

  &__info {
    margin: 0 10px 0 auto;
  }

  &__mailing-button-text {
    @include sm-only {
      @include visually-hidden;
    }
  }
}
