.form-field {
  &__wrapper {
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  &__tooltip {
    margin-left: 10px;
  }
}