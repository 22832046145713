@import '../../../styles/base/variables.scss';

.menu {
  border-right: 0;
  margin-top: 25px;

  &__item {
    padding: 0 0 0 20px !important;
    
    &--active {
      box-shadow: inset -4px 0 0 0 $blue;
    }
  }
}
