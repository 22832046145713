.main-grid {
  display: flex;
  width: 100%;
  height: 100%;

  .content {
    flex-grow: 1;
    width: 100px;
  }
}
