@import '../../../styles/base/variables.scss';
@import '../../../styles/mixins/breakpoints.scss';

.filter {
  &__field-wrapper {
    width: 256px;
    flex-shrink: 1;

    @include sm-only {
      width: 230px;

      label {
        font-size: 12px;
      }
    }
  }

  &__rangepicker {
    width: 100%;
  }
}
