@import '../../../styles/base/variables.scss';
@import '../../../styles/mixins/breakpoints.scss';

.form-controls__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;

  @include md {
    margin: 20px 0 0;
    flex-direction: row;
  }

  button {
    margin: 20px 0 0;
    
    @include md {
      margin: 0 12px 0 0;
    }
  }
}
