// до 576px
@mixin sm-only {
  @media (max-width: $screen-sm-only) {
    @content;
  }
}

// от 576px до 767px
@mixin sm {
  @media (min-width: $screen-sm) {
    @content;
  }
}

// от 768px до 991px
@mixin md {
  @media (min-width: $screen-md) {
    @content;
  }
}

// от 992px до 1199px
@mixin lg {
  @media (min-width: $screen-lg) {
    @content;
  }
}

// от 1200px до 1399
@mixin xl {
  @media (min-width: $screen-xl) {
    @content;
  }
}

// от 1400px
@mixin xxl {
  @media (min-width: $screen-xxl) {
    @content;
  }
}
