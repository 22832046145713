@import "../../../styles/mixins/breakpoints";
@import "../../../styles/base/variables";

.date-filter {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  // margin: 0 0 20px;
  z-index: 1;
  
  @include sm {
    flex-direction: row;
  }

  @include xxl {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }

  &__radio {
    padding-right: 0;

    * {
      border: none !important;
    }

    label {
      padding: 0 20px 0 0;
      border: none;

      @include sm {
        padding: 0 30px 0 0;
      }

      &:focus-within {
        box-shadow: none !important;
      }

      &::before,
      &::after {
        display: none !important;
      }
    }

    @include sm {
      padding-right: 15px;
    }
  }

  &__btn {
    border: none;
  }

  &__range-picker {
    margin: 0;
  }
}
