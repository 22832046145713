.avatar-uploader {
  position: relative;
  width: 100%;
  height: 100%;

  &:hover .mask {
    opacity: 0.8;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
  }

  .upload-button {
    width: 100px;
    margin-top: 8px;
    text-align: center;
  }

  img.upload-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: row;
    padding: 7px;
    background-color: black;
    background-clip: content-box;
    opacity: 0;
    transition: opacity 1s;

    &:hover {
      opacity: 0.8;
    }

    &__button-icon {
      color: white;
    }
    
    &__button:hover &__button-icon,
    &__button-icon:hover {
      color: #1890FF;
    }
  }
}

.preview-image {
  width: 100%;
}
