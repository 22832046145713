@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-weight: 400;
  src: url("/fonts/Roboto-Regular.woff2") format("woff2");
  src: url("/fonts/Roboto-Regular.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-weight: 500;
  src: url("/fonts/Roboto-Medium.woff2") format("woff2");
  src: url("/fonts/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Roboto";
  font-weight: 700;
  src: url("/fonts/Roboto-Bold.woff2") format("woff2");
  src: url("/fonts/Roboto-Bold.woff") format("woff");
}
